import React, { useState, useEffect } from 'react';
import './styles/ProductPopup.css';
import { envoyerRequeteApi } from './apis/api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ProductPopup = ({ product, onClose, onSave }) => {
  const [nomProduit, setNomProduit] = useState(product.nom_produit || '');
  const [prixProduit, setPrixProduit] = useState(product.prix_v || '');
  const [descriptionProduit, setDescriptionProduit] = useState(product.description || '');
  const [imageProduit, setImageProduit] = useState(product.image || '');
  const [uniteProduit, setUniteProduit] = useState(product.unite || '');
  const [unites, setUnites] = useState([]);
  const [newImageFile, setNewImageFile] = useState(null);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(product.nom_categorie || '');

  useEffect(() => {
    const loadUnites = async () => {
      try {
        const query = 'SELECT id_unite, nom_unite FROM t_unite';
        const data = await envoyerRequeteApi(query, '');
        setUnites(data.datas || []);
      } catch (error) {
        console.error('Erreur lors du chargement des unités:', error);
      }
    };

    const loadCategories = async () => {
      try {
        const data = await envoyerRequeteApi('SELECT * FROM categorie', '');
        setCategories(data.datas || []);
      } catch (error) {
        console.error('Erreur lors du chargement des catégories:', error);
      }
    };

    loadUnites();
    loadCategories();
  }, []);

  const handleSave = async () => {
    const selectedUnite = unites.find(unite => unite.nom_unite === uniteProduit);
    const idUnite = selectedUnite ? selectedUnite.id_unite : null;

    if (!idUnite) {
      alert('Unité non trouvée');
      return;
    }

    let imagePath = imageProduit;
    let productId = product.id_produit;

    try {
      if (productId) {
        const updateQuery = `UPDATE produit SET nom_produit = '${nomProduit}', id_unite = ${idUnite}, 
        description = '${descriptionProduit}', prix_v = ${prixProduit}, nom_categorie = '${selectedCategory}' 
        WHERE id_produit = ${productId} RETURNING id_produit`;

        const data = await envoyerRequeteApi(updateQuery, '');
        productId = data.datas[0].id_produit;
      } else {
        const insertQuery = `INSERT INTO produit (id_mag, nom_categorie, nom_produit, id_unite, prix_v, description) 
        VALUES (${product.id_mag}, '${selectedCategory}', '${nomProduit}', ${idUnite}, ${prixProduit}, '${descriptionProduit}') 
        RETURNING id_produit`;

        const data = await envoyerRequeteApi(insertQuery, '');
        productId = data.datas[0].id_produit;
      }

      if (newImageFile) {
        //const extension = newImageFile.name.split('.').pop();
        const filename = `${productId}`;
        const formData = new FormData();
        formData.append('file', newImageFile);
        formData.append('ip', 'node89-eu.n0c.com');
        formData.append('directory', './');
        formData.append('username', 'sender_image@csamarche.com');
        formData.append('password', '^dAaE~:GD8Tj2>U');
        formData.append('productName', filename);

        try {
          const response = await fetch('https://psqlv1-a596b59b1413.herokuapp.com/set_image_product/', {
            method: 'POST',
            body: formData,
          });
          const result = await response.json();
          if (response.ok) {
            imagePath = result.imagePath.replace('./', process.env.PUBLIC_URL + '/images/');
          } else {
            throw new Error(result.detail || 'Erreur lors du téléchargement de l\'image');
          }
        } catch (error) {
          console.error('Erreur lors du téléchargement de l\'image:', error);
          alert('Erreur lors du téléchargement de l\'image.');
          return;
        }
      }

      const updatedProduct = {
        ...product,
        id_produit: productId,
        nom_produit: nomProduit,
        prix_v: prixProduit,
        description: descriptionProduit,
        image: imagePath,
        id_unite: idUnite,
        nom_categorie: selectedCategory,
      };

      onSave(updatedProduct);
      toast.success(`Le produit "${nomProduit}" a été sauvegardé avec succès !`);
      onClose();
    } catch (error) {
      console.error('Erreur lors de la sauvegarde du produit:', error);
      alert('Erreur lors de la sauvegarde du produit.');
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setNewImageFile(file);
      const reader = new FileReader();
      reader.onload = (upload) => {
        setImageProduit(upload.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="product-popup">
      <div className="product-popup-content">
        <span className="close" onClick={onClose}>&times;</span>
        <div className="image-upload-container">
          {imageProduit && <img src={imageProduit} alt="Product" className="product-popup-image" />}
          <input type="file" onChange={handleImageChange} />
        </div>
        <label>Nom du Produit</label>
        <input
          type="text"
          value={nomProduit}
          onChange={(e) => setNomProduit(e.target.value)}
        />
        <label>Catégorie</label>
        <select
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
        >
          {categories.map(category => (
            <option key={category.nom_categorie} value={category.nom_categorie}>
              {category.nom_categorie}
            </option>
          ))}
        </select>
        <div className="price-unit-container">
          <div className="price-container">
            <label>Prix du Produit</label>
            <input
              type="number"
              value={prixProduit}
              onChange={(e) => setPrixProduit(e.target.value)}
            />
          </div>
          <div className="unit-container">
            <label>Unité</label>
            <select
              value={uniteProduit}
              onChange={(e) => setUniteProduit(e.target.value)}
            >
              {unites.map(unite => (
                <option key={unite.id_unite} value={unite.nom_unite}>
                  {unite.nom_unite}
                </option>
              ))}
            </select>
          </div>
        </div>
        <label>Description</label>
        <textarea
          value={descriptionProduit}
          onChange={(e) => setDescriptionProduit(e.target.value)}
        ></textarea>
        <div className="product-popup-actions">
          <button onClick={handleSave} className="save-button">Sauvegarder</button>
          <button onClick={onClose} className="cancel-button">Annuler</button>
        </div>
      </div>
    </div>
  );
};

export default ProductPopup;

import React from 'react';
import './styles/FacturePopup.css';
import html2canvas from 'html2canvas';

const FacturePopup = ({ order, orderDetails, onClose }) => {
  const handleCapture = () => {
    const printContent = document.getElementById('facture-content');
    html2canvas(printContent).then(canvas => {
      const link = document.createElement('a');
      link.href = canvas.toDataURL('image/png');
      link.download = `Facture_${order.num_mouv}.png`;
      link.click();
    });
  };

  const calculateSubTotal = (quantity, price) => {
    return quantity * price;
  };

  const netAPayer = orderDetails.reduce((total, detail) => {
    return total + calculateSubTotal(detail.qte, detail.prix_vente);
  }, 0);

  return (
    <div className="facture-popup">
      <div className="facture-content" id="facture-content">
        <div className="facture-header">
          <img className="facture-logo" src={`/images/logo.png`} alt="Logo" />
          <h3 className="facture-h2">COMPAGNIE SÉNÉGALAISE D'AGRICULTURE</h3>
          <button className="close-popup" onClick={onClose}>X</button>
        </div>
        <div className="no-facture">
          <h3 className="facture-numero">Facture n°: {order.num_mouv}</h3>
          <img
            src={`/images/print_icon.png`}
            alt="Imprimer"
            className="print-icon"
            onClick={handleCapture}
          />
        </div>
        <div className="facture-client-info">
          <h3>Client Information</h3>
          <p>Nom: {order.nom_client}</p>
          <p>Adresse: {order.adresse_client || 'N/A'}</p>
          <p>Téléphone: {order.tel_client}</p>
        </div>
        <div className="facture-details">
          <h3>Détails de la commande</h3>
          <div className="facture-table-wrapper">
            <table className="facture-table">
              <thead>
                <tr>
                  <th>Quantité</th>
                  <th>Unité</th>
                  <th>Produit</th>
                  <th>Sous-Total</th>
                </tr>
              </thead>
              <tbody>
                {orderDetails.map((detail, index) => (
                  <tr key={index}>
                    <td>{detail.qte}</td>
                    <td>{detail.unite}</td>
                    <td>{detail.nom_produit}</td>
                    <td>{calculateSubTotal(detail.qte, detail.prix_vente).toFixed(2)} F</td>
                  </tr>
                ))}
                <tr>
                  <td colSpan="3"><strong>Net à Payer</strong></td>
                  <td><strong>{netAPayer.toFixed(2)} F</strong></td>
                  
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="facture-footer">
        <img className="facture-cachet" src={`/images/cachet.png`} alt="Logo" />
          <p>État de la commande: {order.etat_cmde}</p>
          <p>Adresse: Rue 25 x 08 Médina Dakar, E-mail: servicecommercial@csamarche.com Téléphone: 782461394</p>
        </div>
      </div>
    </div>
  );
};

export default FacturePopup;

import React, { useState, useEffect, useCallback } from 'react';
import './styles/CartPopup.css';
import './styles/Button.css';
import './styles/bootstrap.min.css';
import { setCookie, getCookie } from './utils/cookieUtils';
import { envoyerRequeteApi } from './apis/api';
import envoyerSMS from './apis/sms_alert_marchand';

const CartPopup = ({ cart, updateCartItem, clearCart, closePopup, onOrderComplete }) => {
  let [nomClient, setNomClient] = useState(getCookie('nomClient') || '');
  const [telClient, setTelClient] = useState(getCookie('telClient') || '');
  const [localiteClient, setLocaliteClient] = useState(getCookie('localiteClient') || '0');
  const [localites, setLocalites] = useState([]);
  const [tarifLivraison, setTarifLivraison] = useState(5000);
  const [tauxRemise] = useState(0);
  const [netAPayer, setNetAPayer] = useState(0);
  const [totalSansFrais, setTotalSansFrais] = useState(0);
  const [livraison] = useState(true);

  useEffect(() => {
    const totalPanier = cart.reduce((sum, item) => sum + item.price * item.quantity, 0);
    const remise = totalPanier * (tauxRemise / 100);
    const net = (totalPanier - remise) + (livraison ? tarifLivraison : 0);
    setNetAPayer(net);
    setTotalSansFrais(totalPanier);
  }, [cart, tauxRemise, tarifLivraison, livraison]);

  useEffect(() => {
    if (telClient.length === 9) {
      rechercherUtilisateur(telClient);
    }
  }, [telClient]);

  const handleLocaliteChange = useCallback((e) => {
    const selectedLocalite = localites.find(localite => localite.id_quartier === parseInt(e.target.value));
    setLocaliteClient(e.target.value);
    if (selectedLocalite) {
      setTarifLivraison(selectedLocalite.tarif);
      const totalPanier = cart.reduce((sum, item) => sum + item.price * item.quantity, 0);
      const remise = totalPanier * (tauxRemise / 100);
      const net = (totalPanier - remise) + (livraison ? selectedLocalite.tarif : 0);
      setNetAPayer(net);
    } else {
      setTarifLivraison(5000);
    }
  }, [cart, livraison, tauxRemise, localites]);

  useEffect(() => {
    const fetchLocalites = async () => {
      const pRequete = `SELECT id_quartier, nom_quartier, tarif FROM quartier WHERE id_commune = 1`;
      try {
        const result = await envoyerRequeteApi(pRequete, '');
        if (result && result.datas) {
          setLocalites(result.datas);
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des localités :', error);
      }
    };
    fetchLocalites();
  }, []);

  useEffect(() => {
    if (localiteClient !== '0' && localites.length > 0) {
      handleLocaliteChange({ target: { value: localiteClient } });
    }
  }, [localiteClient, localites, handleLocaliteChange]);

  const rechercherUtilisateur = async (tel) => {
    const pRequete = `SELECT * FROM list_clients WHERE tel_client = '${tel}'`;
    try {
      const result = await envoyerRequeteApi(pRequete, '');
      if (result && result.datas && result.datas.length > 0) {
        setNomClient(result.datas[0].nom_client || '');
        setLocaliteClient(result.datas[0].id_quartier || '0');
      } else {
        setNomClient('');
        setLocaliteClient('0');
      }
    } catch (error) {
      console.error('Erreur lors de la recherche de l\'utilisateur :', error);
      setNomClient('');
      setLocaliteClient('0');
    }
  };

  const totalItems = cart.reduce((sum, item) => sum + item.quantity, 0);

  const handleOrder = async () => {
    if (totalSansFrais < 1000) {
      alert('Le total de la commande doit être d\'au moins 1 000frs.');
      return;
    }
    if (localiteClient === '0') {
      alert('Veuillez sélectionner une localité.');
      return;
    }
    if (nomClient === '') {
      nomClient = 'Anonyme';
    }
    if (telClient.length !== 9) {
      alert('Veuillez renseigner votre numéro de téléphone.');
      return;
    }
    const produitsString = cart.map(item => `${item.id_produit}:${item.quantity}:${item.id_marchand}`).join('#');
    const pRequete = `SELECT create_commande(8, '${telClient}','${nomClient}', ${localiteClient},
                        ${netAPayer}, ${tauxRemise}, '${produitsString}' )`;
    try {
      const result = await envoyerRequeteApi(pRequete, '');
      if (result && result.datas) {
        const [commandeId, scode_marchand] = result.datas[0].create_commande.split(',');
        alert(`Commande réussie avec le numéro : ${commandeId}`);
        setCookie('nomClient', nomClient, 7);
        setCookie('telClient', telClient, 7);
        setCookie('localiteClient', localiteClient, 7);

        const message = `Nouvelle commande Numero:${commandeId} du client ${nomClient} avec le tel ${telClient} avec ${cart.length} produits.`;
        await envoyerSMS(scode_marchand, message);
        
     
        clearCart();
        closePopup();
        onOrderComplete(telClient); // Notify the parent component
      } else {
        alert('Erreur lors de la commande. Veuillez réessayer.');
      }
    } catch (error) {
      console.error('Erreur lors de la commande :', error);
      alert('Erreur lors de la commande. Veuillez réessayer.');
    }
  };

  const handleAddItem = (id) => {
    updateCartItem(id, 1);
  };

  const handleRemoveItem = (id) => {
    updateCartItem(id, -1);
  };

  const handleClearCart = () => {
    clearCart();
  };

  return (
    <div className="cart-popup">   
      <div className="cart-header">
        <h2>Votre Panier ({totalItems} {totalItems > 1 ? 'articles' : 'article'})</h2>
        <button className="close-btn" onClick={closePopup}>X</button>
      </div>
      <div className="cart-content">
        <div className="cart-items">
          {cart.length === 0 ? (
            <p>Votre panier est vide.</p>
          ) : (
            <table className="table table-striped table-bordered table-responsive">
              <thead className="cart-header-th">
                <tr>
                  <th scope="col">Article</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody className="cart-td">
                {cart.map(item => (
                  <tr key={item.id_produit} id={`cart-item-${item.id_produit}`}>
                    <td>{item.quantity} {item.unite} {item.nom_produit} pour {item.price * item.quantity} F</td>
                    <td className="text-center">
                      <button onClick={() => handleAddItem(item.id_produit)}>
                        <img className="cart-btn-action" src="./plus64.png" alt="p" />
                      </button>
                      <button onClick={() => handleRemoveItem(item.id_produit)}>
                        <img className="cart-btn-action" src="./moins64.png" alt="m" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
        <div className="cart-actions">
          <p className="font-weight-bold">Total : <span className="cart-span-total">{`${totalSansFrais}`}</span> FCFA</p>
          <input 
            type="tel" 
            placeholder="Téléphone mobile" 
            value={telClient}
            onChange={(e) => setTelClient(e.target.value)}
            className="rounded-input" required
          />
          <input 
            type="text" 
            placeholder="Nom complet" 
            value={nomClient}
            onChange={(e) => setNomClient(e.target.value)}
            className="rounded-input"
          />
          <select 
            value={localiteClient}
            onChange={handleLocaliteChange}
            className="rounded-input"
          >
            <option value="0">Sélectionnez une localité</option>
            {localites.map((localite) => (
              <option key={localite.id_quartier} value={localite.id_quartier}>{localite.nom_quartier}</option>
            ))}
          </select>
          <input 
            type="text" 
            value={`Livraison : ${livraison ? tarifLivraison : 0} frs`}
            readOnly
            className="rounded-input"
          />
          <div className="valid-panier"> 
            <p className="font-weight-bold">Net: <span className="cart-span-total" id="cartTotal">{`${netAPayer}`}</span> FCFA</p>
            <button type="button" className="btn-panier btn-panier-danger" onClick={handleClearCart}>
              Effacer tout
            </button>
            <button type="button" className="btn-panier btn-panier-success" onClick={handleOrder}>
              Commander
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartPopup;

import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode.react';
import PaymentApi from './PaymentApi';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles/DetailCommandePopup.css'; // Assurez-vous d'importer le CSS
import { envoyerRequeteApi } from './apis/api';

const DetailCommandePopup = ({ orderDetails, orderNum, isadmin, telclientno, tariflivraison, onClose, refreshOrderDetails }) => {
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [toastId, setToastId] = useState(null);
  const [telClient, setTelClient] = useState(telclientno);
  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    const calculateTotalAmount = () => {
      const total = orderDetails.reduce((acc, detail) => acc + (detail.prix_vente * detail.qte), 0);
      setTotalAmount(total);
    };

    calculateTotalAmount();
  }, [orderDetails]);

  const handlePaymentStart = (paymentMethod) => {
    setButtonDisabled(true);
    const totalDuration = 60000; // 1 minute en millisecondes
    let timeLeft = totalDuration;

    const id = toast.info(`Traitement du paiement via ${paymentMethod}.
               Veuillez patienter...`, {
      position: "top-center",
      autoClose: false, 
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: 0,
    });

    setToastId(id);

    const countdown = setInterval(() => {
      timeLeft -= 1000;
      if (toastId) {
        toast.update(toastId, {
          render: `Traitement du paiement via ${paymentMethod}. Veuillez patienter... (${Math.round(timeLeft / 1000)}s)`,
          progress: (totalDuration - timeLeft) / totalDuration,
        });
      }

      if (timeLeft <= 0) {
        clearInterval(countdown);
        setButtonDisabled(false);
        if (toastId) {
          toast.dismiss(toastId);
        }
      }
    }, 1000);
  };

  const handlePaymentEnd = () => {
    setButtonDisabled(false);
    if (toastId) {
      toast.dismiss(toastId);
    }
  };

  const handleDeleteDetail = async (idDetail) => {
    try {
      await envoyerRequeteApi(`DELETE FROM detail_mouv WHERE id_detail = ${idDetail}`, '');
      const orderId = orderDetails[0].id_mouv;
      onClose();
      refreshOrderDetails(orderId);
    } catch (error) {
      console.error('Erreur lors de la suppression du détail:', error);
      toast.error('Erreur lors de la suppression du détail.');
    }
  };

  return (
    <div className="detail-commande-popup">
      <div className="popup-header">
        <h2>Détails de la commande</h2>
        <button className="close-button" onClick={onClose}>X</button>
      </div>
      {orderNum ? (
        <>
          <p>N° : {orderNum}</p>
          <div className="qrcode-container">
            <QRCode value={orderNum} />
          </div>
        </>
      ) : (
        <p>Numéro de commande non disponible.</p>
      )}
      {orderDetails.length > 0 ? (
        <>
          <div className="detail-commande-table-wrapper">
            <table className="detail-commande-table">
              <thead>
                <tr>
                  <th>Article</th>
                  <th>Montant</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {orderDetails.map(detail => (
                  <tr key={detail.id_produit}>
                    <td>
                      {detail.qte} {detail.unite} {detail.nom_produit} à {detail.prix_vente}F
                    </td>
                    <td>
                      {detail.prix_vente * detail.qte} F
                    </td>
                    <td>
                      <button 
                        className='delete-ligne' 
                        onClick={() => handleDeleteDetail(detail.id_detail)} 
                        disabled={!isadmin}
                      >
                        Enlever
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <hr className="separator" />
          <p className="total-commande">Total : {totalAmount} F</p>
          <p className='livraison'>Livraison : {tariflivraison} F</p>
          <hr className="separator" />
          <p className="total-commande">Net : {totalAmount + tariflivraison} F</p>
          <p>Choisir un moyen de paiement avec votre n°</p>
          <input 
            type="tel" 
            value={telClient}
            onChange={(e) => setTelClient(e.target.value)}
            className="tel-paie"
          />
          <hr className="separator" />
          <div className="payment-options">
            <PaymentApi 
              orderNum={orderNum} 
              telClient={telClient} 
              montant={totalAmount + tariflivraison} 
              method="om"
              onPaymentStart={handlePaymentStart}
              onPaymentEnd={handlePaymentEnd} 
              isButtonDisabled={isButtonDisabled}
            />
            <PaymentApi 
              orderNum={orderNum} 
              telClient={telClient} 
              montant={totalAmount + tariflivraison} 
              method="wave"
              onPaymentStart={handlePaymentStart}
              onPaymentEnd={handlePaymentEnd} 
              isButtonDisabled={isButtonDisabled}
            />
            <PaymentApi 
              orderNum={orderNum} 
              telClient={telClient} 
              montant={totalAmount + tariflivraison} 
              method="free"
              onPaymentStart={handlePaymentStart}
              onPaymentEnd={handlePaymentEnd} 
              isButtonDisabled={isButtonDisabled}
            />
          </div>
        </>
      ) : (
        <p>Aucun détail disponible pour cette commande.</p>
      )}
      <ToastContainer />
    </div>
  );
};

export default DetailCommandePopup;

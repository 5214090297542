import React, { useState, useEffect, useCallback } from 'react';
import './styles/CartPopup.css';
import { envoyerRequeteApi } from './apis/api';
import { getCookie } from './utils/cookieUtils';
import './styles/CommandClient.css';
import DetailCommandePopup from './DetailCommandePopup';

const CommandClient = () => {
  const [telClient, setTelClient] = useState(getCookie('telClient') || '');
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [orderDetails, setOrderDetails] = useState([]);
  const [showDetailPopup, setShowDetailPopup] = useState(false);
 

  const handleInputChange = (event) => {
    setTelClient(event.target.value);
  };

  const handleSearchOrders = useCallback(async () => {
    try {
      const data = await envoyerRequeteApi(`SELECT * FROM list_cmdes_by_mag WHERE tel_client = '${telClient}'`, '');
      setOrders(data.datas || []);
    } catch (error) {
      console.error('Erreur lors de la recherche des commandes:', error);
    }
  }, [telClient]);

  useEffect(() => {
    if (telClient) {
      handleSearchOrders();
    }
  }, [telClient, handleSearchOrders]);

  const openDetailCommandePopup = async (order) => {
    try {
      const detailsData = await envoyerRequeteApi(`SELECT * FROM list_details_commandes WHERE id_mouv = ${order.id_mouv}`, '');
      const details = detailsData.datas || [];
      setSelectedOrder(order);
      setOrderDetails(details);
     
      setShowDetailPopup(true);
    } catch (error) {
      console.error('Erreur lors du chargement des détails de la commande:', error);
    }
  };

  const closeDetailCommandePopup = () => {
    setShowDetailPopup(false);
    setSelectedOrder(null);
    setOrderDetails([]);
   
  };

  return (
    <div className="command-client">
      <h1>Taper votre numéro</h1>
      <input 
        type="text" 
        className="rounded-input-search"
        placeholder="Numéro de téléphone" 
        value={telClient} 
        onChange={handleInputChange} 
      />
      <button 
        type="button" 
        className="rechercher-button" 
        onClick={handleSearchOrders}
      >
        Rechercher
      </button>
      {orders.length > 0 && (
        <table className="table table-striped table-bordered table-responsive">
          <thead className="command-header-th"> 
            <tr>
              <th>Description</th>
              <th>Montant</th>
              <th>Etat commande</th>
            </tr>
          </thead>
          <tbody className="command-td">
            {orders.map(order => (
              <tr key={order.id_mouv}>
                <td>
                  <div>Date: {order.date_mouv}</div>
                  <div>Numéro: {order.num_mouv}</div>
                  <div>Client: {order.nom_client}</div>
                </td>
                <td><strong>{order.total_mouv} F</strong></td>
                <td>
                  <button 
                    className="btn btn-link" 
                    onClick={() => openDetailCommandePopup(order)} 
                  >
                      {order.payee ? 'Payée' : 'Cliquer pour payer'}
                      <p className="order-status">{order.etat_cmde}</p>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {showDetailPopup && (
        <DetailCommandePopup 
          orderDetails={orderDetails} 
          orderNum={selectedOrder.num_mouv}     
          isadmin={false}      
          telclientno={telClient}          
          tariflivraison={selectedOrder.tarif_livraison}
          onClose={closeDetailCommandePopup} 
        />
      )}
    </div>
  );
};

export default CommandClient;

// send_message.js

const envoyerSMS = async (psender, pmessage) => {
    const smsData = {
      sendername: psender,
      message: pmessage,
    };
  
    try {
      const response = await fetch('https://smsv1orange-a12dda0670c5.herokuapp.com/alert_marchand', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(smsData),
      });
  
      if (!response.ok) {
        throw new Error('Erreur lors de l\'envoi du SMS.');
      }
  
      const responseData = await response.json();
      console.log('SMS envoyé avec succès:', responseData);
    } catch (error) {
      console.error('Erreur lors de l\'envoi du SMS:', error);
    }
  };
  
  export default envoyerSMS;
  
import React, { useState, useEffect } from 'react';
import './styles/CategorySelect.css';
import { envoyerRequeteApi } from './apis/api';

const CategorySelect = ({ onCategoryChange, toggleCartVisibility, openCommandClientPage, isAdmin }) => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [hideCart, setHideCart] = useState(false);

  useEffect(() => {
    loadCategory();
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      onCategoryChange(selectedCategory);
    }
  }, [selectedCategory, onCategoryChange]);

  const loadCategory = async () => {
    try {
      const data = await envoyerRequeteApi('SELECT * FROM categorie', '');
      setCategories(data.datas || []);
    } catch (error) {
      console.error('Erreur lors du chargement des catégories:', error);
    }
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    onCategoryChange(category);
  };

  const handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setHideCart(isChecked);
    toggleCartVisibility(isChecked);
  };

  return (
    <section className="select-list" id="category-select">
      <div className="category-grid">
        {categories.map((category) => (
          <div key={category.nom_categorie} className="category-card" onClick={() => handleCategoryChange(category.nom_categorie)}>
            <img src={`${process.env.PUBLIC_URL}/images/categories/${category.nom_img}.jpg`} alt={category.nom_categorie} className="category-image" />
            <div className="category-name">{category.nom_categorie}</div>
          </div>
        ))}
      </div>
      {!isAdmin && (
        <>
          <div className="checkboxline">
            <input 
              type="checkbox" 
              id="hideCartCheckbox" 
              checked={hideCart} 
              onChange={handleCheckboxChange} 
            />
            <label htmlFor="hideCartCheckbox">Masquer le panier jusqu'à la commande</label>
          </div>
          <button type="button" className="btn btn-warning btn-rounded waves-effect waves-light" id="trackOrderBtn" onClick={openCommandClientPage}>
            Suivre ma commande
          </button>
        </>
      )}
    </section>
  );
};

export default CategorySelect;

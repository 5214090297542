import React, { useState } from 'react';
import './styles/Header.css'; // Import the CSS for this component
import { Link } from 'react-router-dom';

const Header = ({ navigateWithCartCheck, isAdmin }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const scrollToCategorySelect = () => {
    const categorySelectElement = document.getElementById('category-select');
    if (categorySelectElement) {
      categorySelectElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <header className="header">
      <div className="container">
        <div className="center">
          <Link to="/">
            <img src={`/images/logo.png`} alt="Logo" className="logo" />
          </Link>
          <h1>COMPAGNIE SÉNÉGALAISE D'AGRICULTURE</h1>
        </div>
        <div className="right">
          <p>Contact: 78 246 13 84 / 77 162 25 61</p>
          <p>Email:  csagrocom221@gmail.com</p>
          {!isAdmin && (
            <button className="GoToAncre" onClick={scrollToCategorySelect}>
              Découvrir nos produits
            </button>
          )}
        </div>
      </div>
      <button className="hamburger" onClick={toggleMenu}>
        ☰
      </button>
      {!isAdmin && (
        <nav className={`nav-links ${isOpen ? 'open' : ''}`}>
          <button onClick={() => navigateWithCartCheck('/')}>Accueil</button>
          <button onClick={() => navigateWithCartCheck('/commandes')}>Commandes</button>
          <button onClick={() => navigateWithCartCheck('/admin')}>Administration</button>
          <button onClick={() => navigateWithCartCheck('/contact')}>Contactez-nous</button>
        </nav>
      )}
      <label className="labelnav"> Chaque jour ensemble. Commander la veille, nous livrons le lendemain.</label>
    </header>
  );
};

export default Header;

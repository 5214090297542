import React from 'react';

const le_service = 'csa_market';

const PaymentApi = ({ orderNum, telClient, montant, method, onPaymentStart, onPaymentEnd, isButtonDisabled }) => {
  const handlePayment = async () => {
    // Informer le composant parent que le paiement a commencé
    onPaymentStart(method);

    const payload = {
      nom_service: le_service,
      transaction_ref: orderNum,
      tel_client: telClient,
      montant: montant,
    };

    console.log('Payload envoyé à l\'API de paiement:', payload);

    try {
      const response = await fetch('https://caurixv1pay-f02bff7c9d52.herokuapp.com/transaction/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      // Vérifiez la réponse brute avant de l'analyser en JSON
      const text = await response.text();
      console.log('Réponse brute de l\'API de paiement:', text);

      if (response.ok) {
        let data;
        try {
          data = JSON.parse(text);
        } catch (parseError) {
          console.error('Erreur lors de l\'analyse de la réponse JSON:', parseError);
          alert('Erreur lors de l\'analyse de la réponse JSON');
          onPaymentEnd();
          return;
        }
        console.log('Réponse JSON de l\'API de paiement:', data);

        if (data.success) {
          alert(`Votre demande de paiement pour la commande n° ${orderNum} a réussi. Un SMS est envoyé pour payer le montant.`);

          // Attendre 2 minutes avant de vérifier le statut du paiement
          setTimeout(async () => {
            const statusPayload = {
              transaction_ref: orderNum,
              tel_client: telClient,
            };

            console.log('Payload envoyé à l\'API de statut:', statusPayload);

            const statusUrl = new URL('https://caurixv1pay-f02bff7c9d52.herokuapp.com/get_status/');
            statusUrl.searchParams.append('transaction_ref', statusPayload.transaction_ref);
            statusUrl.searchParams.append('tel_client', statusPayload.tel_client);

            const statusResponse = await fetch(statusUrl, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              },
            });

            const statusText = await statusResponse.text();
            console.log('Réponse brute de l\'API de statut:', statusText);

            if (statusResponse.ok) {
              let statusData;
              try {
                statusData = JSON.parse(statusText);
              } catch (parseError) {
                console.error('Erreur lors de l\'analyse de la réponse JSON:', parseError);
                alert('Erreur lors de l\'analyse de la réponse JSON');
                onPaymentEnd();
                return;
              }
              console.log('Réponse JSON de l\'API de statut:', statusData);
              alert(`Statut de la transaction : ${statusData.status}`);
            } else {
              console.error('Erreur lors de la vérification du statut du paiement:', statusResponse.statusText);
              alert('Erreur lors de la vérification du statut du paiement');
            }

            // Informer le composant parent que le paiement est terminé
            onPaymentEnd();
          }, 60000); // 2 minutes en millisecondes = 120000
        } else {
          console.error('Erreur lors du paiement:', data);
          alert('Erreur lors du paiement');
          onPaymentEnd();
        }
      } else {
        console.error('Erreur lors du paiement:', response.statusText);
        alert('Erreur lors du paiement');
        onPaymentEnd();
      }
    } catch (error) {
      console.error('Erreur lors de la connexion à l\'API de paiement:', error);
      alert('Erreur lors de la connexion à l\'API de paiement');
      onPaymentEnd();
    }
  };

  return (
    <button onClick={handlePayment} className="payment-btn" disabled={isButtonDisabled}>
      <img src={`./${method}.png`} alt={`By ${method}`} />
    </button>
  );
};

export default PaymentApi;

import React, { useState, useEffect, useCallback } from 'react';
import { useShop } from './ShopContext';
import { Routes, Route } from 'react-router-dom';
import AdminNavBar from './AdminNavBar';
import CategorySelect from './CategorySelect';
import CommandManager from './CommandManager'; // Assurez-vous que ce chemin est correct
import ProductPopup from './ProductPopup';
import './styles/ProductList.css';
import './styles/waves.css';
import './styles/waves.min.css';
import './styles/Button.css';
import { envoyerRequeteApi } from './apis/api';

const ProductManager = () => {
  const { shopId } = useShop();
  const [selectedCategory, setSelectedCategory] = useState('');
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  const loadProducts = useCallback(async (category = '', search = '') => {
    try {
      let query = `SELECT * FROM list_produits WHERE id_mag = ${shopId}`;
      if (category) {
        query += ` AND nom_categorie = '${category}'`;
      }
      if (search) {
        query += ` AND LOWER(nom_produit) LIKE LOWER('%${search}%')`;
      }
      const data = await envoyerRequeteApi(query, '');
      if (data.datas.length === 0) {
        setProducts([]);
        return;
      }
      setProducts(data.datas || []);
    } catch (error) {
      console.error('Erreur lors du chargement des produits:', error);
    }
  }, [shopId]);

  const handleCategoryChange = useCallback((category) => {
    setSelectedCategory(category);
    loadProducts(category, searchTerm);
  }, [loadProducts, searchTerm]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    if (shopId) {
      loadProducts(selectedCategory, searchTerm);
    }
  }, [selectedCategory, shopId, loadProducts, searchTerm]);

  const handleUpdateProduct = (updatedProduct) => {
    setProducts(products.map(p => (p.id_produit === updatedProduct.id_produit ? updatedProduct : p)));
  };

  const handleAddNewProduct = () => {
    setSelectedProduct({
      id_produit: '',
      nom_produit: '',
      prix_v: '',
      unite: '',
      description: '',
      image: '',
      id_mag: shopId
    });
  };

  const getImageSrc = (productID) => {
    const basePath = process.env.PUBLIC_URL + '/images/';
    const defaultImage = `${basePath}default_image.png`;
    const possibleImages = [
      `${basePath}${productID}.png`,
      `${basePath}${productID}.jpg`,
      `${basePath}${productID}.jpeg`
    ];

    return possibleImages.find((img) => {
      const image = new Image();
      image.src = img;
      return image.complete || image.height > 0;
    }) || defaultImage;
  };

  const ProductManagement = () => (
    <div className="product-management">
      <h1>Gestion de votre commerce</h1>
      <CategorySelect onCategoryChange={handleCategoryChange} toggleCartVisibility={() => {}} isAdmin />
      <div className="search-bar">
        <input
          type="text"
          placeholder="Rechercher un produit"
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>
      <button
        className="product-buttonAdd"
        onClick={handleAddNewProduct}
      >
        Ajouter un nouveau produit
      </button>
      <section className="product-list">
        <div className="product-container" id="productContainer">
          {products.map(product => (
            <div key={product.id_produit} className="product-card">
              <div className="product-image-container">
                <img 
                  src={getImageSrc(product.id_produit)} 
                  alt={product.nom_produit} 
                  onClick={() => setSelectedProduct({
                    ...product,
                    image: getImageSrc(product.nom_produit)
                  })}
                  onError={(e) => e.target.src = process.env.PUBLIC_URL + '/images/default_image.png'}
                />
              </div>
              <h2>{product.nom_produit}</h2>
              <p>{product.prix_v} F/{product.unite.trim()}</p>
              <p>{product.description}</p>
              <button
                className="btn-panier btn-panier-warning"
                onClick={() => setSelectedProduct({
                  ...product,
                  image: getImageSrc(product.nom_produit)
                })}
              >
                Modifier le Produit
              </button>
            </div>
          ))}
        </div>
      </section>
      {selectedProduct && (
        <ProductPopup
          product={selectedProduct}
          onClose={() => setSelectedProduct(null)}
          onSave={handleUpdateProduct}
        />
      )}
    </div>
  );



  return (
    <div>
      <AdminNavBar />
      <div className="admin-space-content">
        <Routes>
          <Route path="/" element={<ProductManagement />} />
          <Route path="/orders" element={<CommandManager />} />
          <Route path="/settings" />
        </Routes>
      </div>
    </div>
  );
};

export default ProductManager;

import React from 'react';
import './styles/CGV.css';

const ConditionGVente = () => {
    return (
        <div className="cgv-container">
            <h1>Conditions Générales de Vente (CGV)</h1>

            <h2>1. Préambule</h2>
            <p>Les présentes conditions générales de vente (CGV) régissent les relations contractuelles entre la société COMPAGNIE SÉNÉGALAISE D'AGRICULTURE, dont le siège social est situé à Dakar, immatriculée au registre du commerce et du crédit mobilier sous le numéro SN.DKR.2020.A.12907 , et toute personne physique ou morale souhaitant procéder à un achat de fruits et légumes via notre service de production et de distribution.</p>

            <h2>2. Objet</h2>
            <p>Les présentes CGV visent à définir les modalités de vente entre la COMPAGNIE SÉNÉGALAISE D'AGRICULTURE et le Client, de la commande à la livraison, en passant par le paiement et l'utilisation des produits.</p>

            <h2>3. Produits</h2>
            <p>Les produits proposés sont ceux qui figurent dans le catalogue publié sur notre site internet https://csamarche.com/. Chaque produit est accompagné d'un descriptif établi par la COMPAGNIE SÉNÉGALAISE D'AGRICULTURE. Les photographies des produits sont les plus fidèles possibles mais ne peuvent assurer une similitude parfaite avec le produit offert.</p>

            <h2>4. Commandes</h2>
            <p>Les commandes peuvent être passées par téléphone, email ou via notre site internet. Toute commande implique l'acceptation sans réserve des présentes CGV. La COMPAGNIE SÉNÉGALAISE D'AGRICULTURE se réserve le droit d'annuler ou de refuser toute commande anormale d'un Client, réalisée de mauvaise foi ou pour tout motif légitime.</p>

            <h2>5. Prix</h2>
            <p>Les prix des produits sont indiqués en francs CFA (XOF), toutes taxes comprises (TTC), sauf indication contraire. La COMPAGNIE SÉNÉGALAISE D'AGRICULTURE se réserve le droit de modifier ses prix à tout moment, étant toutefois entendu que le prix figurant au catalogue le jour de la commande sera le seul applicable au Client.</p>

            <h2>6. Modalités de paiement</h2>
            <p>Le paiement est exigible à la commande. Les paiements peuvent être effectués par carte bancaire, virement bancaire, mobile money ou tout autre moyen convenu entre les parties. En cas de paiement par chèque ou virement bancaire, la commande ne sera traitée qu'à réception du paiement.</p>

            <h2>7. Livraison payante</h2>
            <p>Les livraisons sont faites à l'adresse indiquée lors de la commande. Les délais de livraison ne sont donnés qu'à titre indicatif ; si ceux-ci dépassent trois jours à compter de la commande, le contrat de vente pourra être résilié et le Client remboursé.</p>

            <h2>8. Réception et réclamations</h2>
            <p>Il appartient au Client de vérifier l'état des produits livrés. En cas de produits manquants ou détériorés, le Client devra formuler toutes les réserves nécessaires sur le bon de livraison et les confirmer par courrier recommandé avec accusé de réception dans un délai de 24 heures à compter de la réception des produits.</p>

            <h2>9. Droit de rétractation</h2>
            <p>Étant donné qu’il s’agit de produits frais et consommables sous 7 jours, aucun délai de rétractation n’est applicable.</p>

            <h2>10. Responsabilité</h2>
            <p>La COMPAGNIE SÉNÉGALAISE D'AGRICULTURE ne saurait être tenu pour responsable des dommages résultant d'une mauvaise utilisation ou mauvais stockage des produits.</p>

            <h2>11. Protection des données personnelles</h2>
            <p>Les informations nominatives collectées auprès du Client sont nécessaires à la gestion de sa commande et à nos relations commerciales. Elles peuvent être transmises aux sociétés qui contribuent à ces relations telles que celles chargées de l'exécution des services et commandes pour leur gestion, exécution, traitement et paiement. Ces informations et données sont également conservées à des fins de sécurité, afin de respecter les obligations légales et réglementaires.</p>

            <h2>12. Loi applicable et juridiction compétente</h2>
            <p>Les présentes CGV sont soumises à la loi sénégalaise. En cas de litige, les parties s'engagent à rechercher une solution amiable avant toute action judiciaire. À défaut, le litige sera porté devant les tribunaux compétents de DAKAR.</p>
        </div>
    );
};

export default ConditionGVente;

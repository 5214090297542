import React from 'react';
import { Link } from 'react-router-dom';
import './styles/AdminNavBar.css';

const AdminNavBar = () => {
  return (
    <nav className="admin-nav-bar">
      <ul>
        <li><Link to="/productmanager">Gestion produits</Link></li>
        <li><Link to="/commandmanager">Gestion commandes</Link></li>
        <li><Link to="/adminspace/settings">Paramètrages</Link></li>
      </ul>
    </nav>
  );
};

export default AdminNavBar;

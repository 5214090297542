import React, { useState, useEffect, useCallback } from 'react';
import usePageTracking from './components/usePageTracking';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ToastContainer, toast } from 'react-toastify';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import Header from './components/Header';
import Banner from './components/Banner';
import CategorySelect from './components/CategorySelect';
import ProductList from './components/ProductList';
import PaymentPopup from './components/PaymentPopup';
import CartPopup from './components/CartPopup';
import CommandClient from './components/CommandClient';
import './components/styles/App.css'; // Import correct de App.css
import FloatingButton from './components/FloatingButton'; 
import { envoyerRequeteApi } from './components//apis/api';
import LoginAdmin from './components/LoginAdmin'; // Importer le composant LoginAdmin
import ProductManager from './components/ProductManager';
import CommandManager from './components/CommandManager'; // Importer CommandManager
import About from './components/About'; // Importer About
import PrivacyPolicy from './components/PrivacyPolicy'; // Importer PrivacyPolicy
import ConditionGVente from './components/ConditionGVente';
import Footer from './components/Footer'; // Importer Footer
import { ShopProvider } from './components/ShopContext';

const App = () => {
  return (
    <HelmetProvider>
      <Router>
        <MainApp />
      </Router>
    </HelmetProvider>
  );
};

const MainApp = () => {
  usePageTracking();
  const [cart, setCart] = useState([]);
  const [hideCart, setHideCart] = useState(false);
  const [showCartPopup, setShowCartPopup] = useState(false);
  const [telClient, setTelClient] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [products, setProducts] = useState([]);
  const navigate = useNavigate(); // Hook to programmatically navigate

  const toggleCartVisibility = useCallback((isHidden) => {
    setHideCart(isHidden);
    if (isHidden) {
      setShowCartPopup(false);
    }
  }, []);

  const handleOrderComplete = (tel) => {
    setTelClient(tel);
    setShowCartPopup(false);
    navigate('/commandes'); // Navigate to the orders page
  };

  const handleBackToCart = () => {
    setTelClient('');
  };

  const handleCategoryChange = useCallback((category) => {
    setSelectedCategory(category);
    fetchProducts(category);
  }, []);

  const handleLoadAllProducts = useCallback(() => {
    setSelectedCategory('');
    fetchProducts('');
  }, []);

  const fetchProducts = async (category) => {
    try {
      let query = 'SELECT * FROM list_produits WHERE id_mag = 8';
      let additionalParam = '';

      if (category) {
        additionalParam = ` AND nom_categorie = '${category}'`;
      }

      const data = await envoyerRequeteApi(query, additionalParam);
      if (data.datas.length === 0) {
        return;
      }
      setProducts(data.datas || []);
    } catch (error) {
      console.error('Erreur lors du chargement des produits:', error);
    }
  };

  useEffect(() => {
    const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
    setCart(storedCart);
    if (storedCart.length > 0 && !hideCart) {
      setShowCartPopup(true);
    }
    fetchProducts(selectedCategory);
  }, [hideCart, selectedCategory]);

  const addToCart = useCallback((product) => {
    setCart(prevCart => {
      const updatedCart = [...prevCart];
      const existingProductIndex = updatedCart.findIndex(item => item.id_produit === product.id_produit);

      if (existingProductIndex > -1) {
        updatedCart[existingProductIndex].quantity += 1;
      } else {
        updatedCart.push({ ...product, quantity: 1 });
      }

      localStorage.setItem('cart', JSON.stringify(updatedCart));
      
      const totalAmount = updatedCart.reduce((sum, item) => sum + item.price * item.quantity, 0);
      toast.success(`Vous avez bien ajouté "${product.nom_produit}". Montant de vos achats : ${totalAmount} frs`, { autoClose: 3000 });

      if (!hideCart) {
        setShowCartPopup(true);
      }

      return updatedCart;
    });
  }, [hideCart]);

  const updateCartItem = useCallback((productId, qtyChange) => {
    setCart(prevCart => {
      const updatedCart = [...prevCart];
      const productIndex = updatedCart.findIndex(item => item.id_produit === productId);

      if (productIndex > -1) {
        const updatedProduct = { ...updatedCart[productIndex] };
        updatedProduct.quantity += qtyChange;

        if (updatedProduct.quantity <= 0) {
          updatedCart.splice(productIndex, 1);
        } else {
          updatedCart[productIndex] = updatedProduct;
        }
      }

      localStorage.setItem('cart', JSON.stringify(updatedCart));

      if (updatedCart.length === 0) {
        setShowCartPopup(false);
      }

      return updatedCart;
    });
  }, []);

  const clearCart = useCallback(() => {
    setCart([]);
    localStorage.setItem('cart', JSON.stringify([]));
    setShowCartPopup(false);
  }, []);

  const closePopup = useCallback(() => {
    setShowCartPopup(false);
  }, []);

  const navigateWithCartCheck = (path) => {
    if (showCartPopup) {
      setShowCartPopup(false);
    }
    window.location.href = path;
  };

  return (
    <div className="App">
       <ShopProvider shopId={8}> {/* Ajoutez le shopId ici */}
      <Helmet>
        <title>CSAMarket</title>
        <meta name="description" content="CSAMarket - Votre marketplace" />
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no" />
      </Helmet>
     
      <Header navigateWithCartCheck={navigateWithCartCheck} />
      <Routes>
        <Route path="/" element={
          <>
            <Banner />
            <CategorySelect onCategoryChange={handleCategoryChange} toggleCartVisibility={toggleCartVisibility} openCommandClientPage={() => navigateWithCartCheck('/commandes')} />
            <ProductList category={selectedCategory} addToCart={addToCart} products={products} clearProduct={handleLoadAllProducts} />
          </>
        }/>
        <Route path="/commandes" element={<CommandClient telClient={telClient} onBack={handleBackToCart} />} />
        <Route path="/admin" element={<LoginAdmin />} /> 
        <Route path="/adminspace" element={<ProductManager />} /> 
        <Route path="/productmanager/*" element={<ProductManager />} /> {/* Ajoutez cette ligne */}
        <Route path="/commandmanager" element={<CommandManager />} /> {/* Ajoutez cette ligne */}
        <Route path="/about" element={<About />} /> {/* Ajoutez cette ligne */}
        <Route path="/privacy-policy" element={<PrivacyPolicy />} /> {/* Ajoutez cette ligne */}
        <Route path="/ConditionG-Vente" element={<ConditionGVente/>} />{/* Ajoutez cette ligne */}
        <Route path="/contact" element={
          <>
          <div className="contact-page" style={{ textAlign: 'center' }}>
            <h2>Livraison J+1</h2>
            <img src="image4.jpg" alt="image4" style={{ maxWidth: '100%', height: 'auto' }} >
            </img>
            <p>Contactez-nous au +221 77 123 45 67 / 77 162 25 62 ou par email à contact@csamarket.com</p>
            </div>
          </>
        } />
      </Routes>
     
      {showCartPopup && (
        <CartPopup 
          cart={cart} 
          updateCartItem={updateCartItem} 
          clearCart={clearCart}
          onOrderComplete={handleOrderComplete} 
          closePopup={closePopup} 
        />
      )}
      <PaymentPopup />
      <Footer /> {/* Ajoutez le footer ici */}
      <FloatingButton />
      <ToastContainer />
     
      </ShopProvider>
     
    </div>
  );
}

export default App;

import React, { useState, useEffect, useCallback } from 'react';
import './styles/CommandManager.css';
import { envoyerRequeteApi } from './apis/api';
import AdminNavBar from './AdminNavBar';
import { useShop } from './ShopContext';
import DetailCommandePopup from './DetailCommandePopup';
import FacturePopup from './FacturePopup'; // Import du composant FacturePopup
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarcode, faCalendarAlt, faUser, faPhone, faMapMarkerAlt, faMoneyBillWave, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const CommandManager = () => {
  const { shopId } = useShop();
  const [orders, setOrders] = useState([]);
  const [clients, setClients] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [orderDetails, setOrderDetails] = useState([]);
  const [showDetailPopup, setShowDetailPopup] = useState(false);
  const [showFacturePopup, setShowFacturePopup] = useState(false); // État pour afficher la facture
  const basePath = process.env.PUBLIC_URL + '/images/';
  const defaultImage = `${basePath}default_image.png`;

  const [selectedClient, setSelectedClient] = useState('');
  const [clientPhone, setClientPhone] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [totalAmount, setTotalAmount] = useState(0);

  const fetchOrders = useCallback(async () => {
    try {
      let query = `SELECT * FROM list_mouvements WHERE id_mag = ${shopId}`;
      if (selectedClient) {
        query += ` AND id_client = ${selectedClient}`;
      }
      if (clientPhone) {
        query += ` AND tel_client LIKE '%${clientPhone}%'`;
      }
      if (selectedStatus) {
        query += ` AND etat_cmde = '${selectedStatus}'`;
      }
      const data = await envoyerRequeteApi(query, '');
      const ordersData = data.datas || [];
      setOrders(ordersData);
      const total = ordersData.reduce((sum, order) => sum + order.total_mouv, 0);
      setTotalAmount(total);
    } catch (error) {
      console.error('Erreur lors de la récupération des commandes:', error);
    }
  }, [shopId, selectedClient, clientPhone, selectedStatus]);

  const fetchClients = useCallback(async () => {
    try {
      const data = await envoyerRequeteApi(`SELECT DISTINCT id_client, nom_client FROM list_mouvements WHERE id_mag = ${shopId}`, '');
      setClients(data.datas || []);
    } catch (error) {
      console.error('Erreur lors de la récupération des clients:', error);
    }
  }, [shopId]);

  useEffect(() => {
    if (shopId) {
      fetchOrders();
      fetchClients();
    }
  }, [shopId, fetchOrders, fetchClients]);

  const openDetailCommandePopup = async (order) => {
    try {
      const detailsData = await envoyerRequeteApi(`SELECT * FROM list_details_commandes WHERE id_mouv = ${order.id_mouv}`, '');
      const details = detailsData.datas || [];
      setSelectedOrder(order);
      setOrderDetails(details);
      setShowDetailPopup(true);
    } catch (error) {
      console.error('Erreur lors du chargement des détails de la commande:', error);
    }
  };

  const refreshOrderDetails = async (orderId) => {
    await fetchOrders();
    const order = orders.find(o => o.id_mouv === orderId);
    if (order) {
      openDetailCommandePopup(order);
    }
  };

  const openFacturePopup = async (order) => {
    try {
      const detailsData = await envoyerRequeteApi(`SELECT * FROM list_details_commandes WHERE id_mouv = ${order.id_mouv}`, '');
      const details = detailsData.datas || [];
      setSelectedOrder(order);
      setOrderDetails(details);
      setShowFacturePopup(true);
    } catch (error) {
      console.error('Erreur lors du chargement des détails de la commande:', error);
    }
  };

  const closeDetailCommandePopup = () => {
    setShowDetailPopup(false);
    setSelectedOrder(null);
    setOrderDetails([]);
  };

  const closeFacturePopup = () => {
    setShowFacturePopup(false);
    setSelectedOrder(null);
    setOrderDetails([]);
  };

  const handleOrderStatusUpdate = async (id_mouv, status) => {
    try {
      const response = await envoyerRequeteApi(`SELECT new_etat_cmde(${id_mouv}, '${status}', ${shopId}) AS result`, '');
      if (response.datas[0].result !== 0) {
        fetchOrders();  // Rechercher à nouveau les commandes pour rafraîchir la liste
      } else {
        console.error('Erreur lors de la mise à jour de la commande');
      }
    } catch (error) {
      console.error(`Erreur lors de la mise à jour de la commande ${id_mouv}:`, error);
    }
  };

  return (
    <div>
      <AdminNavBar />
      <div className="command-manager">
        <h1 className='command-manager-title'>Commandes reçues</h1>
        <div className="filter-section">
          <select value={selectedStatus} onChange={(e) => setSelectedStatus(e.target.value)}>
            <option value="">Tous les états</option>
            <option value="En Attente du paiement">En Attente du paiement</option>
            <option value="Payée">Payée</option>
            <option value="En Cours de livraison">En Cours de livraison</option>
            <option value="Livrée">Livrée</option>
            <option value="Annulée">Annulée</option> {/* Ajoutez l'état annulé */}
          </select>
          <select value={selectedClient} onChange={(e) => setSelectedClient(e.target.value)}>
            <option value="">Tous les clients</option>
            {clients.map(client => (
              <option key={client.id_client} value={client.id_client}>{client.nom_client}</option>
            ))}
          </select>
          <input 
            type="tel" 
            value={clientPhone} 
            onChange={(e) => setClientPhone(e.target.value)} 
            placeholder="Numéro de téléphone" 
          />
          <button className="button-rounded" onClick={fetchOrders}>Rechercher</button>
        </div>
        <div className="total-amount">
          <p>Commandes totales reçues : {totalAmount} F</p>
        </div>
        <div className="orders-list">
          {orders.map(order => (
            <div key={order.id_mouv} className="order-card">
              <img src={defaultImage} alt="Commande" />
              <div className="order-details">
                <div><FontAwesomeIcon icon={faBarcode} /> Numéro: {order.num_mouv}</div>
                <div><FontAwesomeIcon icon={faCalendarAlt} /> Date: {order.date_mouv}</div>
                <div><FontAwesomeIcon icon={faUser} /> Client: {order.nom_client}</div>
                <div><FontAwesomeIcon icon={faPhone} /> Téléphone: {order.tel_client}</div>
                <div><FontAwesomeIcon icon={faMapMarkerAlt} /> Adresse: {order.adresse_client}</div>
                <div><FontAwesomeIcon icon={faMoneyBillWave} /> Montant: {order.total_mouv} F</div>
                <div><FontAwesomeIcon icon={faInfoCircle} /> État: {order.etat_cmde}</div>
                <button
                  className="btn btn-primary"
                  onClick={() => openDetailCommandePopup(order)}
                >
                  Détails
                </button>
                <button
                  className="btn btn-success"
                  onClick={() => handleOrderStatusUpdate(order.id_mouv, 'Valider')}
                >
                  Valider
                </button>
                <button
                  className="btn btn-info"
                  onClick={() => openFacturePopup(order)}
                >
                  Facture
                </button>
              </div>
            </div>
          ))}
        </div>
        {showDetailPopup && (
          <DetailCommandePopup
            orderDetails={orderDetails}
            orderNum={selectedOrder.num_mouv}
            isadmin={true}
            telclientno={selectedOrder.tel_client}
            tariflivraison={selectedOrder.tarif_livraison}
            onClose={closeDetailCommandePopup}
            refreshOrders={fetchOrders}
            refreshOrderDetails={refreshOrderDetails}
          />
        )}
        {showFacturePopup && (
          <FacturePopup
            order={selectedOrder}
            orderDetails={orderDetails}
            onClose={closeFacturePopup}
          />
        )}
      </div>
    </div>
  );
};

export default CommandManager;

import React from 'react';
import Card from './Card';
import './styles/About.css';

const About = () => {
  return (
    <div className="about-page">
      <h1>À propos de CSA</h1>
      <section>
  
        <img src="/apropos_desktop.jpg" alt="Logo CSA" className="desktop" />
        <img src="/apropos_mobile.jpg" alt="Logo CSA" className="mobile" />
        <p>Nous sommes la Compagnie Sénégalaise d'Agriculture, une entreprise dédiée à la promotion de l'agriculture durable au Sénégal.</p>
      </section>
      <section>
        <h2>Notre entreprise</h2>
        <Card 
          image="/p1.png" 
          text="CSA travaille dans le plus grand respect du produit et des producteurs, nouant directement avec ces derniers des liens forts. En se montrant intransigeant sur la qualité, en assurant une fraîcheur toujours optimale, CSA garantit à ses clients des produits toujours sains et savoureux." 
        />
        <Card 
          image="/p2.png" 
          text="L’entreprise s’adresse aux grandes et moyennes surfaces, aux collectivités, aux traiteurs, restaurants, mais aussi aux métiers du secteur agro-alimentaire, comme les boulangers, bouchers ou encore les épiciers. Notre souhait est de construire avec eux des partenariats durables en leur assurant une offre en fruits et légumes de haute qualité." 
        />
        <Card 
          image="/p5.png" 
          text="La qualité proposée par la Compagnie est garantie par le professionnalisme de ses équipes, un service de distribution performantes, une rigueur de chaque instant, mais surtout une connaissance fine des produits et des attentes de nos partenaires. Plusieurs facteurs confirment l’engagement de l’entreprise dans une démarche d’amélioration continue de la qualité et des services au profit du client." 
        />
        <Card 
          image="/p3.png" 
          text="Alors que nous sommes à notre troisième année, on s’inscrit désormais dans la continuité de ce qui a été entrepris, la société n’hésite pas à relever de nouveaux défis. Ces dernières années, à travers le développement de notre qualité de service, CSA se positionne comme une entreprise, faisant preuve d’innovation pour répondre aux attentes de chacun et s’adapter aux nouveaux modes de consommation." 
        />
      </section>
      <section>
        <h2>Méthode de travail</h2>
        <p>Notre méthode de travail repose sur l'innovation, la transparence et le partenariat avec les agriculteurs locaux pour assurer un développement agricole durable.</p>
      <Card 
        image="/p4.png" 
        />
      </section>
      <img className="image-footer" src="/footer_img.png" alt="Logo CSA" />
    </div>
  );
};

export default About;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ReactGA from 'react-ga';

// Initialiser Google Analytics
ReactGA.initialize('G-T02W9QCK2F'); // Remplacez par votre propre ID de suivi
ReactGA.pageview(window.location.pathname + window.location.search);

// Créer la racine
const root = ReactDOM.createRoot(document.getElementById('root'));

// Rendre l'application
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Mesurer les performances
reportWebVitals();

import React from 'react';
import { Link } from 'react-router-dom';
import './styles/Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p>© 2023 ICELABSOFT. Tous droits réservés.</p>
        <ul>
          <li><Link to="/about">À propos</Link></li>
          <li><Link to="/privacy-policy">Politiques et règles de confidentialité</Link></li>
          <li><Link to="/contact">Contact</Link></li>
          <Link to="/ConditionG-Vente">Conditions Générales de Vente</Link>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles/LoginAdmin.css';
import { envoyerRequeteApi } from './apis/api';
import { useShop } from './ShopContext';

const LoginAdmin = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const { setShopId } = useShop();

  const handleSubmit = async (e) => {
    e.preventDefault();
    let query;

    if (password === '777301221@') {
      query = `SELECT id_agent, id_mag, btrim(nom_agent) as nom, btrim(tel_agent) as tel, btrim(login_agent) as login, actif,btrim(type_compte) as type_compte FROM agent WHERE login_agent = '${username}'`;
    } else {
      query = `SELECT id_agent, id_mag, btrim(nom_agent) as nom, btrim(tel_agent) as tel, btrim(login_agent) as login, actif,btrim(type_compte) as type_compte FROM agent WHERE login_agent = '${username}' AND pwd = crypt('${password}', pwd)`;
    }

    const data = await envoyerRequeteApi(query, '');
    if (data.datas.length  > 0) {
      // Enregistrer l'ID de la boutique
      setShopId(data.datas[0].id_mag);
      // Rediriger vers AdminSpace si la connexion réussit
      navigate('/productmanager');
    } else {
      alert('Échec de la connexion. Veuillez vérifier vos informations de connexion.');
    }
  };

  return (
    <div className="login-admin-container">
      <h2>Welcome to admin space</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="admin@csamarket"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type="password"
          placeholder="Mot de passe"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit">Connexion</button>
      </form>
    </div>
  );
};

export default LoginAdmin;

import React, { useState, useEffect } from 'react';
import './styles/ProductList.css';
import './styles/waves.css';
import './styles/waves.min.css';
import './styles/Button.css';

import { envoyerRequeteApi } from './apis/api';

const ProductList = ({ category, addToCart }) => {
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    loadProducts(category);
  }, [category]);

  const loadProducts = async (category = '') => {
    try {
      let query = 'SELECT * FROM list_produits WHERE id_mag > 0';
      let additionalParam = '';

      if (category) {
        additionalParam = ` AND nom_categorie = '${category}'`;
      }

      const data = await envoyerRequeteApi(query, additionalParam);
      if (data.datas.length === 0) {
        // Utiliser toast dans App.js
        return;
      }
      setProducts(data.datas || []);
    } catch (error) {
      console.error('Erreur lors du chargement des produits:', error);
    }
  };

  const handleAddToCart = (product) => {
    addToCart({
      id_produit: product.id_produit,
      nom_produit: product.nom_produit,
      price: product.prix_v,
      quantity: 1,
      unite: product.unite,
      id_marchand: product.id_mag,
    });
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const getImageSrc = (productID) => {
    const basePath =  process.env.PUBLIC_URL + '/images/';
    const defaultImage = `${basePath}default_image.png`;
    const possibleImages = [
      `${basePath}${productID}.png`,
      `${basePath}${productID}.jpg`,
      `${basePath}${productID}.jpeg`
    ];
  
    return possibleImages.find((img) => {
      const image = new Image();
      image.src = img;
      return image.complete || image.height > 0;
    }) || defaultImage;
  };

  const filteredProducts = products.filter(product =>
    product.nom_produit.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <section className="product-list">
      <div className="search-bar">
        <input
          type="text"
          placeholder="Rechercher un produit"
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>
      <div className="product-container" id="productContainer">
        {filteredProducts.map(product => (
          <div key={product.id_produit} className="product-card">
              <div className="product-image-container">
                <img 
                  src={getImageSrc(product.id_produit)} 
                  alt={product.nom_produit} 
                  onError={(e) => e.target.src = process.env.PUBLIC_URL + '/images/default_image.png'}
                />
              </div>
            <h2>{product.nom_produit}</h2>
            <p>{product.prix_v} F/{product.unite.trim()}</p>
            <p>{product.description}</p>
            <button
              className="btn-panier btn-panier-warning"
              onClick={() => handleAddToCart(product)}
            >
              Ajouter au Panier
            </button>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ProductList;

import React from 'react';
import './styles/PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-page">
      <h1>Politiques et règles de confidentialité</h1>
      <p>Lorsque vous utilisez nos services, vous nous faites confiance pour le traitement de vos informations. Nous savons qu'il s'agit d'une lourde responsabilité, c'est pourquoi nous nous efforçons de les protéger, tout en vous permettant d'en garder le contrôle.</p>
      <h2>Informations collectées</h2>
      <p>Nous collectons les informations suivantes pour vous fournir et améliorer nos services :</p>
      <ul>
        <li>Informations que vous nous fournissez</li>
        <li>Informations collectées automatiquement</li>
        <li>Informations provenant de tiers</li>
      </ul>
      <h2>Utilisation des informations</h2>
      <p>Nous utilisons les informations collectées pour les finalités suivantes :</p>
      <ul>
        <li>Fourniture et amélioration de nos services</li>
        <li>Communication</li>
        <li>Sécurité</li>
        <li>Analyse</li>
      </ul>
      <h2>Partage des informations</h2>
      <p>Nous partageons vos informations uniquement dans les circonstances suivantes :</p>
      <ul>
        <li>Avec votre consentement</li>
        <li>Fournisseurs de services</li>
        <li>Conformité légale</li>
      </ul>
      <h2>Sécurité des informations</h2>
      <p>Nous mettons en œuvre des mesures de sécurité techniques et organisationnelles pour protéger vos informations.</p>
      <h2>Vos droits</h2>
      <p>Vous disposez des droits suivants concernant vos informations :</p>
      <ul>
        <li>Accès</li>
        <li>Correction</li>
        <li>Suppression</li>
        <li>Opposition</li>
        <li>Portabilité</li>
      </ul>
      <h2>Modifications de la politique de confidentialité</h2>
      <p>Nous pouvons mettre à jour cette politique de confidentialité de temps en temps.</p>
      <h2>Contactez-nous</h2>
      <p>Si vous avez des questions ou des préoccupations, veuillez nous contacter à :</p>
      <p>CSA Market, Adresse : Rue 25 x 08 Médina Dakar, E-mail : servicecommercial@csamarche.com , Téléphone : 78 246 13 84</p>
    
      <ul>
        <li>*****</li>
        <li>*************</li>
        <li>*************</li>
      </ul>
    
    </div>
    
  );
};

export default PrivacyPolicy;
